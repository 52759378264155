/**
 * Usage:
 *
 * @returns {{ init(): void }}
 */
export default (tabs = null) => ({
  title: '',
  category: '',
  excerpt: '',
  url: '',
  imgSrc : '',
  activeIndex : 1,
  isInteracting: false,
  tabs: tabs,
  timeout : null,

  init() {
    if (this.tabs) {
      this.updateColors(this.tabs[0], 1);
    }
    this.timeout = setTimeout(() => {
      this.updateIndex();
    }, 4000);
  },

  decrement() {
    if(this.activeIndex == 1) {
      this.activeIndex = 4;
    } else {
      this.activeIndex --;
    }
    this.updateColors(this.tabs[this.activeIndex - 1], this.activeIndex);
  },

  increment() {
    if(this.activeIndex == 4) {
      this.activeIndex = 1;
    } else {
      this.activeIndex ++;
    }
    this.updateColors(this.tabs[this.activeIndex - 1], this.activeIndex);

  },

  resetTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.updateIndex();
    }, 4000);
  },

  themes: {
    'white': {
      theme: 'dynamic-theme-bg-white'
    },
    'neutral-50': {
      theme: 'dynamic-theme-bg-light-gray'
    },
    'blue-200': {
      theme: 'dynamic-theme-bg-light-blue'
    },
    'blue-400': {
      theme: 'dynamic-theme-bg-primary-blue'
    },
    'blue-900': {
      theme: 'dynamic-theme-bg-dark-blue'
    },
    'mint-300': {
      theme: 'dynamic-theme-bg-mint'
    },
    'orange-200': {
      theme: 'dynamic-theme-bg-light-orange'
    },
    'orange-400': {
      theme: 'dynamic-theme-bg-primary-orange'
    },
  },

  updateClasses(theme, target) {
    target.setAttribute('data-dynamic-bg', theme);
  },

  updateColors(bg, loopIndex) {
    this.activeIndex = loopIndex;

    if (bg) {
      let theme = this.themes[bg].theme;
      let targets = this.targets ? this.targets : document.querySelectorAll("[data-dynamic-bg]");
      if (!this.targets) {
        this.targets = targets;
      }
      if (theme && targets) {
        for (let i = 0; i < targets.length; i++) {
          this.updateClasses(theme, targets[i]);
        }
      }
    }
  },


  updateIndex() {
    this.timeout = setTimeout(() => {
      this.updateIndex();
    }, 4000);
    if(this.isInteracting) return;
    if(this.activeIndex < 4) {
      this.activeIndex ++;
    } else {
      this.activeIndex = 1;
    }
    this.updateColors(this.tabs[this.activeIndex - 1], this.activeIndex);
  },
});
