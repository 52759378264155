/**
 * Usage:
 *
 *   Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import {function} from "./gaCustomEvents";
 *   Alpine.data('function', function);
 *   Alpine.start();

 *
 * @param selector
 * @returns {{init(): void}}
 */

export function gaGlobalSearch() {
  return {
    searchTerm: "",
    debug: import.meta.env.MODE == 'development',

    dispatchSearchEvent() {
      // No reason sending a blank search term (even if it is possible)
      if (!this.searchTerm && this.debug) {
        console.log("gaGlobalSearch: returning from dispatch event as search term is blank.");
        return;
      }

      if (this.debug) {
        console.log(`GA EVENT: <global_site_search> - <${this.searchTerm}>`);
        return;
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'global_site_search',
        'search_term': this.searchTerm
      });
    },

    sendSearchTerms() {
      this.searchTerm = this.$el.value;
      this.dispatchSearchEvent();
    }
  };
}
