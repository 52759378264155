import MmenuLight from 'mmenu-light';

export default () => {

  const button = document.getElementById('ham-trigger');

  button.addEventListener('click', () => {
    button.classList.toggle('menu-open');
  });

  const menu = new MmenuLight(
    document.querySelector('#mobile-menu-nav'),
    "(max-width: 1280px)"
  );

  const navigator = menu.navigation({
    // options
    // Default options ['light', 'dark']
    theme: 'light',
    // slidingSubmenus: false,
    title: 'Fero Labs'
  });

  const drawer = menu.offcanvas({
    // options
    // position: 'right'
  });

  document.querySelector('[data-mobile-menu-trigger]')
    .addEventListener("click", (event) => {
      event.preventDefault();
      drawer.open();
    });

  let mobileNodes = document.getElementsByClassName('mobile-node');

  for (let i = 0; i < mobileNodes.length; i++) {
    mobileNodes[i].addEventListener("click", (event) => {
      drawer.close();
    });
  }
};
