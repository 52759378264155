import Headroom from "headroom.js";

export default () => {
  const header = document.querySelector("header");
  if (header.classList.contains('headroom')) {
    const options = {
      // vertical offset in px before element is first unpinned
      offset: 0,
      tolerance: 2,
    };
    const headroom = new Headroom(header, options);
    headroom.init();
  }
};
