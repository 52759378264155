import Alpine from 'alpinejs';

// import componentNameHere from '@js/parts/alpine/componentNameHere';
import freeform from '@js/parts/alpine/freeform';
import smoothScroll from '@js/parts/alpine/smoothScroll';
import swiper from '@js/parts/alpine/swiper';
import tabbedSection from '@js/parts/alpine/tabbedSection';
import roleTabbedSection from '@js/parts/alpine/roleTabbedSection';
import tippy from '@js/parts/alpine/tippy';
import insights from '@js/parts/alpine/insights';
import {gaGlobalSearch} from "@js/parts/alpine/gaCustomEvents";

import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';

// 1st party plugins
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(intersect);

// Components
// Alpine.data("componentNameHere", componentNameHere);
Alpine.data("freeform", freeform);
Alpine.data("smoothScroll", smoothScroll);
Alpine.data("swiper", swiper);
Alpine.data("tabbedSection", tabbedSection);
Alpine.data("roleTabbedSection", roleTabbedSection);
Alpine.data("tippy", tippy);
Alpine.data("insights", insights);
Alpine.data("gaGlobalSearch", gaGlobalSearch);

window.Alpine = Alpine;
Alpine.start();
