/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./tabbedSection";
 *   Alpine.data('tabbedSection', tabbedSection);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="tabbedSection()" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */
export default (tabs = null) => ({

  activeIndex: 1,
  tabs: tabs,
  themes: {
    'white': {
      theme: 'dynamic-theme-bg-white'
    },
    'neutral-50': {
      theme: 'dynamic-theme-bg-light-gray'
    },
    'blue-200': {
      theme: 'dynamic-theme-bg-light-blue'
    },
    'blue-400': {
      theme: 'dynamic-theme-bg-primary-blue'
    },
    'blue-900': {
      theme: 'dynamic-theme-bg-dark-blue'
    },
    'mint-300': {
      theme: 'dynamic-theme-bg-mint'
    },
    'orange-200': {
      theme: 'dynamic-theme-bg-light-orange'
    },
    'orange-400': {
      theme: 'dynamic-theme-bg-primary-orange'
    },
  },

  init() {
    if (this.tabs) {
      this.updateColors(this.tabs[0], 1);
    }
  },

  updateColors(bg, loopIndex) {
    this.activeIndex = loopIndex;

    if (bg) {
      let theme = this.themes[bg].theme;
      let targets = this.targets ? this.targets : document.querySelectorAll("[data-dynamic-bg]");
      if (!this.targets) {
        this.targets = targets;
      }
      if (theme && targets) {
        for (let i = 0; i < targets.length; i++) {
          this.updateClasses(theme, targets[i]);
        }
      }
    }
  },

  updateClasses(theme, target) {
    target.setAttribute('data-dynamic-bg', theme);
  }
});
